import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../../styles/breakPoints'
import OfficialCommonH3Set from '../../molecules/officialCommonH3Set'

const Wrapper = styled.section`
  width: 100%;
  height: 100%;

  .p-section-inner {
    display: flex;
    max-width: 1080px;
    padding: 60px 0;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;

    .p-section-download {
      width: 100%;
      margin: 0 auto;
      display: block;
      text-align: center;
    }
  }

  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-download {
      }
    }
  }
`
/**
 * LP経費精算問い合わせ-入力後画面
 * @constructor
 */
const LpExpenseThanks01 = () => (
  <Wrapper className="done">
    <div className="p-section-inner">
      <OfficialCommonH3Set
        label="お問い合わせありがとうございます"
        description={
          'キンクラの経費精算へ興味を持ってくださり、ありがとうございます。\nお問い合わせいただいた内容を確認し\n2～3営業日以内に、担当よりご連絡いたします。'
        }
      />
      <div className="p-section-download">
        <img
          src="/images/official/download/imgSendMail.png"
          alt="メールを送信しました"
        />
      </div>
    </div>
  </Wrapper>
)

export default LpExpenseThanks01
