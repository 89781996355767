import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React from 'react'
import SEO from '../../components/atoms/seo'
import OfficialContactLayout from '../../components/organisms/officialContactLayout'
import BreakPoints from '../../styles/breakPoints'
import LpExpenseThanks01 from '../../components/organisms/lp-expense/lpExpenseThanks01'

const Wrapper = styled.main`
  font-size: 16px;
  height: 100%;

  .p-head-inner {
    .p-nav-area {
      display: none;
    }
  }

  ${BreakPoints.xLarge} {
    .p-head-inner {
      .p-nav-area {
        display: flex;
      }
    }
  }
`

/**
 * LP-経費精算-お問い合わせ完了
 * @constructor
 */
const LpExpenseThanksPage = () => (
  <OfficialContactLayout>
    <SEO title="キンクラ経費精算お問い合わせ" />
    <Wrapper className="thanks">
      <LpExpenseThanks01 />
    </Wrapper>
  </OfficialContactLayout>
)

export default LpExpenseThanksPage

export const pageQuery = graphql`
  query LpExpenseThanksPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
